import * as React from 'react';

function IconsLogoFacebook2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12.0619C22 6.50335 17.5206 2 12 2C6.47563 2 2 6.50335 2 12.0619C2 17.0829 5.65562 21.246 10.4375 22V14.9711H7.89813V12.0626H10.4375V9.84454C10.4375 7.3234 11.9281 5.93108 14.2131 5.93108C15.3075 5.93108 16.4531 6.12728 16.4531 6.12728V8.60315H15.19C13.95 8.60315 13.5625 9.37981 13.5625 10.1753V12.0619H16.3356L15.89 14.9705H13.5625V21.9994C18.3406 21.2454 22 17.0822 22 12.0613V12.0619Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsLogoFacebook2);
