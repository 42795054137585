import * as React from 'react';

function IconsContentQuote(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 12.0834H4"
        stroke="#6D7D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 20.0834H4"
        stroke="#6D7D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33464 16.0834H2.66797"
        stroke="#6D7D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0743 24.0834C10.3912 27.7919 15.6526 29.0688 20.3005 27.2934C24.9483 25.5181 28.0186 21.0587 28.0185 16.0833C28.0185 11.1079 24.9482 6.64861 20.3003 4.87331C15.6525 3.098 10.3911 4.37498 7.07422 8.08344"
        stroke="#6D7D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7922 13.3442L14.8315 11.4437C15.0653 11.0161 15.5139 10.7501 16.0013 10.7501C16.4887 10.7501 16.9373 11.0161 17.1712 11.4437L18.2105 13.3442C18.3328 13.568 18.5168 13.752 18.7406 13.8743L20.641 14.9136C21.0687 15.1475 21.3346 15.5961 21.3346 16.0835C21.3346 16.5709 21.0687 17.0195 20.641 17.2533L18.7405 18.2926C18.5168 18.415 18.3328 18.599 18.2105 18.8227L17.1711 20.7232C16.9373 21.1509 16.4887 21.4168 16.0013 21.4168C15.5139 21.4168 15.0653 21.1509 14.8315 20.7232L13.7921 18.8227C13.6698 18.599 13.4858 18.415 13.2621 18.2926L11.3616 17.2533C10.9339 17.0195 10.668 16.5709 10.668 16.0835C10.668 15.5961 10.9339 15.1475 11.3616 14.9136L13.2621 13.8743C13.4858 13.752 13.6698 13.568 13.7922 13.3442Z"
        stroke="#6D7D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconsContentQuote);
