import * as React from 'react';

function IconsLearningDocumentChart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99705 2.24628C3.47781 2.24628 2.24622 3.47787 2.24622 4.99711V17.0021C2.24622 18.5214 3.47781 19.7529 4.99705 19.7529H8.99872C9.41293 19.7529 9.74872 19.4172 9.74872 19.0029C9.74872 18.5887 9.41293 18.2529 8.99872 18.2529H4.99705C4.30623 18.2529 3.74622 17.6929 3.74622 17.0021V4.99711C3.74622 4.30629 4.30623 3.74628 4.99705 3.74628H18.0025C18.6933 3.74628 19.2533 4.30629 19.2533 4.99711V9.99919C19.2533 10.4134 19.5891 10.7492 20.0033 10.7492C20.4175 10.7492 20.7533 10.4134 20.7533 9.99919V4.99711C20.7533 3.47787 19.5217 2.24628 18.0025 2.24628H4.99705ZM9.24883 10.2496C8.83461 10.2496 8.49883 10.5854 8.49883 10.9996C8.49883 11.4138 8.83461 11.7496 9.24883 11.7496H12C12.4142 11.7496 12.75 11.4138 12.75 10.9996C12.75 10.5854 12.4142 10.2496 12 10.2496H9.24883ZM8.49883 7.12299C8.49883 6.70877 8.83461 6.37299 9.24883 6.37299H16.0016C16.4159 6.37299 16.7516 6.70877 16.7516 7.12299C16.7516 7.5372 16.4159 7.87299 16.0016 7.87299H9.24883C8.83461 7.87299 8.49883 7.5372 8.49883 7.12299ZM6.12252 10.1246C5.63924 10.1246 5.24747 10.5163 5.24747 10.9996C5.24747 11.4829 5.63924 11.8747 6.12252 11.8747C6.6058 11.8747 6.99757 11.4829 6.99757 10.9996C6.99757 10.5163 6.6058 10.1246 6.12252 10.1246ZM5.24747 7.12304C5.24747 6.63976 5.63924 6.24799 6.12252 6.24799C6.6058 6.24799 6.99757 6.63976 6.99757 7.12304C6.99757 7.60632 6.6058 7.99809 6.12252 7.99809C5.63924 7.99809 5.24747 7.60632 5.24747 7.12304ZM15.0012 12.2504C14.587 12.2504 14.2512 12.5862 14.2512 13.0004V16.5023H12C11.5858 16.5023 11.25 16.838 11.25 17.2523V21.0038C11.25 21.418 11.5858 21.7538 12 21.7538H15.0012L15.0108 21.7538H17.9947L18.0025 21.7538H21.0037C21.4179 21.7538 21.7537 21.418 21.7537 21.0038V15.2514C21.7537 14.8372 21.4179 14.5014 21.0037 14.5014H18.7525V13.0004C18.7525 12.5862 18.4167 12.2504 18.0025 12.2504H15.0012ZM15.7512 20.2538V13.7504H17.2525V20.2538H15.7512ZM12.75 20.2538V18.0023H14.2512V20.2538H12.75ZM18.7525 20.2538H20.2537V16.0014H18.7525V20.2538Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsLearningDocumentChart);
