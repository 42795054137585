import * as React from 'react';

function IconsFeaturePhone(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40467 8.13477C4.22038 7.15811 4.5051 6.17315 5.18789 5.49041L5.78866 4.88967C6.5083 4.17008 7.67484 4.17008 8.39448 4.88967L9.47624 5.97137C10.0162 6.5113 10.0162 7.38569 9.39792 8.00393L8.64326 8.75762C8.44331 8.95756 8.37605 9.26069 8.49031 9.5196C9.03027 10.7441 9.84297 11.9511 10.9211 13.0291C11.9991 14.1071 13.2062 14.9207 14.4308 15.4616C14.6897 15.5758 14.9929 15.5086 15.1928 15.3086L16.0249 14.4757C16.5639 13.9358 17.4393 13.9358 17.9792 14.4757L19.0601 15.5565C19.7797 16.2761 19.7797 17.4425 19.0601 18.1621L18.4602 18.7619C17.7774 19.4447 16.7924 19.7294 15.8157 19.5451C13.408 19.09 10.7718 17.6913 8.51518 15.4348C6.25859 13.1784 4.85986 10.5423 4.40467 8.13477ZM14.9637 13.4156C14.9636 13.4157 14.9638 13.4155 14.9637 13.4156C16.0892 12.2885 17.9147 12.2899 19.0399 13.415L20.1207 14.4958C21.4262 15.8012 21.4262 17.9174 20.1207 19.2228L19.5209 19.8226C18.4665 20.8769 16.9715 21.2896 15.5376 21.0191C12.7936 20.5004 9.89363 18.9345 7.45456 16.4955C5.01549 14.0566 3.44955 11.1573 2.93078 8.41343C2.66021 6.97951 3.07293 5.48399 4.12726 4.42972L4.72803 3.82898C6.03345 2.52364 8.14969 2.52364 9.4551 3.82898L10.5369 4.91068C11.7158 6.08954 11.6081 7.91518 10.4585 9.06462L10.1039 9.41878C10.5584 10.2999 11.1839 11.1707 11.9817 11.9684C12.7796 12.7664 13.6507 13.3926 14.532 13.8477L14.9637 13.4156Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsFeaturePhone);
