import * as React from 'react';

function IconsInfoLinearthinkingAmbassador(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="currentColor"
      />
      <path
        d="M18.3662 16.8705L16.5479 13.2335C17.1431 12.3557 17.4912 11.2974 17.4912 10.1591C17.4912 7.13063 15.0273 4.66675 11.9988 4.66675C8.9703 4.66675 6.50643 7.13063 6.50643 10.1591C6.50643 11.2974 6.85454 12.3558 7.44984 13.2338L5.63127 16.8705C5.56469 17.0038 5.57185 17.1619 5.65007 17.2886C5.7284 17.4153 5.86671 17.4925 6.01564 17.4925H8.10198L9.35378 19.1615C9.43546 19.2703 9.56303 19.3334 9.69753 19.3334C9.87578 19.3334 10.0162 19.2271 10.0819 19.0959L11.806 15.6477C11.87 15.65 11.9343 15.6515 11.9988 15.6515C12.0634 15.6515 12.1276 15.65 12.1916 15.6477L13.9157 19.0959C13.9812 19.2269 14.1217 19.3334 14.3001 19.3334C14.4345 19.3334 14.5622 19.2703 14.6437 19.1615L15.8957 17.4925H17.982C18.1309 17.4925 18.2692 17.4153 18.3475 17.2886C18.4258 17.1619 18.4329 17.0038 18.3662 16.8705ZM9.62423 18.0897L8.66057 16.805C8.57944 16.6968 8.4521 16.6331 8.31682 16.6331H6.71086L8.04424 13.9666C8.80134 14.7525 9.78962 15.3144 10.8986 15.5408L9.62423 18.0897ZM7.3658 10.1591C7.3658 7.60451 9.44419 5.52612 11.9988 5.52612C14.5534 5.52612 16.6318 7.60451 16.6318 10.1591C16.6318 12.7138 14.5534 14.7922 11.9988 14.7922C9.44419 14.7922 7.3658 12.7138 7.3658 10.1591ZM15.6807 16.6331C15.5455 16.6331 15.4182 16.6968 15.337 16.805L14.3734 18.0897L13.0989 15.5408C14.208 15.3143 15.1964 14.7525 15.9535 13.9663L17.2867 16.633L15.6807 16.6331Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M14.1921 10.872L15.3921 9.43973C15.4872 9.32626 15.5172 9.17184 15.4715 9.03108C15.4257 8.8902 15.3107 8.78289 15.1671 8.74697L13.3545 8.29356L12.3629 6.70965C12.2844 6.5841 12.1468 6.50789 11.9987 6.50789C11.8507 6.50789 11.713 6.5841 11.6345 6.70965L10.6432 8.29356L8.83067 8.74697C8.687 8.78289 8.57196 8.8902 8.5262 9.03096C8.48054 9.17184 8.51042 9.32626 8.60553 9.43973L9.80553 10.872L9.6764 12.7359C9.66621 12.8836 9.73268 13.0262 9.85241 13.1132C10.0342 13.2454 10.2192 13.1828 10.2656 13.1641L11.9987 12.4657L13.7318 13.1642C13.8691 13.2195 14.0252 13.2003 14.1449 13.1133C14.2648 13.0263 14.3312 12.8837 14.321 12.736L14.1921 10.872ZM13.4222 10.4528C13.3508 10.5381 13.3152 10.6476 13.3229 10.7584L13.4164 12.1105L12.1594 11.6038C12.0201 11.5477 11.8979 11.5797 11.8381 11.6038L10.5812 12.1105L10.6749 10.7586C10.6825 10.6477 10.6469 10.5381 10.5755 10.4528L9.70515 9.4141L11.0198 9.08524C11.1277 9.05827 11.2208 8.99057 11.2798 8.89635L11.9988 7.7475L12.718 8.89635C12.777 8.99057 12.8701 9.05827 12.9779 9.08524L14.2926 9.4141L13.4222 10.4528Z"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default React.memo(IconsInfoLinearthinkingAmbassador);
