import * as React from 'react';

function IconsContentQuote(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.76903 9.47102C10.1066 9.32857 10.4922 9.47708 10.6646 9.80037L11.3995 11.1783C11.6088 11.5709 11.4203 12.0535 11.023 12.2538C10.2879 12.6244 9.73996 13.0802 9.37938 13.621C8.96178 14.3369 8.75298 14.6949 8.75298 14.6949C8.75298 14.6949 9.05126 14.7545 9.64783 14.8738C10.2444 14.9335 10.9006 15.1423 11.6165 15.5002C12.3921 15.8582 13.0781 16.4249 13.6747 17.2005C14.2713 17.9163 14.5695 18.9305 14.5695 20.243C14.5695 21.9134 13.973 23.3451 12.7798 24.5383C11.6463 25.6718 10.2742 26.2087 8.6635 26.149C6.9931 26.0894 5.56133 25.4331 4.36819 24.1803C3.23471 22.8679 2.66797 21.287 2.66797 19.4376C2.66797 17.111 3.38385 15.0528 4.81562 13.2631C6.12011 11.5781 7.77124 10.3141 9.76903 9.47102ZM24.5341 9.47102C24.8717 9.32857 25.2573 9.47708 25.4297 9.80037L26.1646 11.1783C26.3739 11.5709 26.1854 12.0535 25.7881 12.2538C25.053 12.6244 24.5051 13.0802 24.1445 13.621C23.7269 14.3369 23.5181 14.6949 23.5181 14.6949C23.5181 14.6949 23.8164 14.7545 24.4129 14.8738C25.0095 14.9335 25.6657 15.1423 26.3816 15.5002C27.1572 15.8582 27.8432 16.4249 28.4398 17.2005C29.0364 17.9163 29.3346 18.9305 29.3346 20.243C29.3346 21.9134 28.7381 23.3451 27.5449 24.5383C26.4114 25.6718 25.0393 26.2087 23.4286 26.149C21.7582 26.0894 20.3264 25.4331 19.1333 24.1803C17.9998 22.8679 17.4331 21.287 17.4331 19.4376C17.4331 17.111 18.149 15.0528 19.5807 13.2631C20.8852 11.5781 22.5363 10.3141 24.5341 9.47102Z"
        fill="#D2D8DF"
      />
    </svg>
  );
}

export default React.memo(IconsContentQuote);
