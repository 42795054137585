import * as React from 'react';

function IconsMapMini(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.5"
        cx="11.5018"
        cy="11.1082"
        r="11.0487"
        fill="#FAD6D6"
      />
      <circle
        cx="11.5025"
        cy="11.108"
        r="6.44506"
        fill="#D42525"
        stroke="white"
        strokeWidth="1.84144"
      />
    </svg>
  );
}

export default React.memo(IconsMapMini);
