import * as React from 'react';

function IconsSchoolLearningCertificateMedal(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.2503 14.5235V17.5469C16.2503 17.6752 16.1912 17.7963 16.0901 17.8753C15.9889 17.9543 15.8571 17.9822 15.7326 17.9511L14.7858 17.7144C14.6531 17.6813 14.5142 17.6813 14.3815 17.7144L13.4347 17.9511C13.3102 17.9822 13.1784 17.9543 13.0772 17.8753C12.9761 17.7963 12.917 17.6752 12.917 17.5469V14.5234"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="14.5837"
        cy="12.1305"
        r="2.91667"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.8802H4.16667C3.24619 15.8802 2.5 15.134 2.5 14.2135V4.21354C2.5 3.29307 3.24619 2.54688 4.16667 2.54688H15.8333C16.7538 2.54688 17.5 3.29307 17.5 4.21354V7.54687"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 6.29655H13.333"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 9.21354H9.16634"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 12.1305H8.33301"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconsSchoolLearningCertificateMedal);
