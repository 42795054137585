import * as React from 'react';

function IconsGraphicAnchorSelectEdit(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C16 2.89572 16.8957 2 18 2H20C21.1043 2 22 2.89572 22 4V6C22 7.10428 21.1043 8 20 8V9C20 9.55228 19.5523 10 19 10C18.4477 10 18 9.55228 18 9V8C16.8957 8 16 7.10428 16 6H8C8 7.10428 7.10428 8 6 8V16C7.10428 16 8 16.8957 8 18H10C10.5523 18 11 18.4477 11 19C11 19.5523 10.5523 20 10 20H8C8 21.1043 7.10428 22 6 22H4C2.89572 22 2 21.1043 2 20V18C2 16.8957 2.89572 16 4 16V8C2.89572 8 2 7.10428 2 6V4C2 2.89572 2.89572 2 4 2H6C7.10428 2 8 2.89572 8 4H16ZM18 6V5V4H20V6H19H18ZM6 4V5V6H5H4V4H6ZM6 19V18H5H4V20H6V19ZM21 12.5857C20.219 11.8046 18.9521 11.8033 18.1709 12.5866L18.1572 12.6005L13.2965 17.4613C13.2847 17.4729 13.2733 17.4848 13.2621 17.497C13.0993 17.6749 13 17.9118 13 18.172V21V21.0001C13 21.026 13.001 21.0516 13.0029 21.077C13.0422 21.5933 13.4736 22.0001 14 22.0001H16.828C17.1139 22.0001 17.3717 21.8801 17.554 21.6878L22.4113 16.8305L22.4141 16.8277C23.1952 16.0466 23.1965 14.7798 22.4132 13.9985C22.4099 13.9952 22.4065 13.9919 22.4031 13.9886C22.4005 13.986 22.3978 13.9835 22.3952 13.9809L21.0102 12.596L21 12.5857ZM19.586 14.0002L20.9996 15.4138L16.4133 20.0001H15V18.5862L19.586 14.0002Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsGraphicAnchorSelectEdit);
