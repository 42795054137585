import * as React from 'react';

function IconsRankingCrowns(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9994 4C10.8808 4 9.97799 4.90282 9.97799 6.02147C9.97799 6.75856 10.3701 7.40207 10.9576 7.75522C10.4239 9.84002 9.32933 11.8639 7.9041 12.0869H7.20326C6.13998 11.9554 4.86486 11.2627 4.05391 10.0837C4.38527 9.7235 4.58786 9.24309 4.58786 8.71627C4.58786 7.60165 3.68101 6.6948 2.56639 6.6948C1.45178 6.6948 0.5 7.60165 0.5 8.71627C0.5 9.7068 1.25166 10.5332 2.20128 10.705L4.37777 18.1072C4.50292 18.5328 4.89352 18.8251 5.33716 18.8251L18.6647 18.8251C19.1083 18.8251 19.4989 18.5328 19.6241 18.1072L21.8007 10.7046C22.7493 10.5321 23.4999 9.7061 23.4999 8.71627C23.4999 7.60165 22.5482 6.6948 21.4335 6.6948C20.3189 6.6948 19.4121 7.60165 19.4121 8.71627C19.4121 9.24367 19.6151 9.72455 19.9471 10.0849C19.2852 11.0466 18.3144 11.6846 17.4019 11.9581C17.1964 12.0196 16.9939 12.0627 16.7986 12.0869H16.0977C14.6723 11.8638 13.5774 9.83899 13.0439 7.75363C13.63 7.40013 14.0209 6.75745 14.0209 6.02147C14.0209 4.90282 13.1181 4 11.9994 4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsRankingCrowns);
