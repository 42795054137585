import * as React from 'react';

function IconsFeatureShinestar3(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 19C10.5 13.05 7.95 10.5 2 10.5C7.94994 10.5 10.5 7.95 10.5 2C10.5 7.94998 13.05 10.5 19 10.5C13.05 10.5 10.5 13.05 10.5 19ZM18 22C18 19.2 16.8 18 14 18C16.8 18 18 16.8 18 14C18 16.8 19.2 18 22 18C19.2 18 18 19.2 18 22ZM16 5C18.1 5 19 5.9 19 8C19 5.9 19.9 5 22 5C19.9 5 19 4.09999 19 2C19 4.1 18.1 5 16 5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsFeatureShinestar3);
