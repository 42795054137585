import * as React from 'react';

function IconsCommunicationMessageChat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75022 5.49798C3.75022 4.53272 4.53272 3.75022 5.49798 3.75022H12.509C13.4743 3.75022 14.2568 4.53272 14.2568 5.49798V9.5073C14.2568 9.97084 14.0726 10.4154 13.7449 10.7432C13.4171 11.0709 12.9725 11.2551 12.509 11.2551H8.70938C8.51043 11.2551 8.31963 11.3341 8.17896 11.4748L6.25163 13.4022V12.0052C6.25163 11.5909 5.91579 11.2551 5.50152 11.2551H5.49798C5.03444 11.2551 4.58989 11.0709 4.26212 10.7432C3.93436 10.4154 3.75022 9.97084 3.75022 9.5073V5.49798ZM5.49798 2.25C3.70417 2.25 2.25 3.70417 2.25 5.49798V9.5073C2.25 10.3687 2.59218 11.1948 3.2013 11.804C3.6312 12.2339 4.1692 12.5308 4.75141 12.6683V14.0053C4.75141 14.511 5.05602 14.967 5.52328 15.1605C5.99052 15.3541 6.52834 15.2471 6.88595 14.8895L6.88597 14.8895L9.02009 12.7553H12.509C13.3704 12.7553 14.1966 12.4131 14.8057 11.804C15.4148 11.1948 15.757 10.3687 15.757 9.5073V5.49798C15.757 3.70417 14.3028 2.25 12.509 2.25H5.49798ZM18.0079 8.75363C17.5937 8.75363 17.2578 9.08947 17.2578 9.50374C17.2578 9.91801 17.5937 10.2538 18.0079 10.2538H18.5117C19.477 10.2538 20.2595 11.0363 20.2595 12.0016V16.0752C20.2595 17.0375 19.4818 17.8182 18.5206 17.8229L18.5086 17.8228C18.0944 17.8228 17.7585 18.1587 17.7585 18.5729V19.9057L15.9403 18.0874C15.8038 17.9269 15.601 17.8246 15.3743 17.8229L15.3576 17.8229H12.5017C11.5364 17.8229 10.7539 17.0404 10.7539 16.0751V15.0068C10.7539 14.5925 10.4181 14.2566 10.0038 14.2566C9.58956 14.2566 9.25372 14.5925 9.25372 15.0068V16.0751C9.25372 17.8689 10.7079 19.3231 12.5017 19.3231H15.0543L17.1242 21.393C17.4818 21.7506 18.0196 21.8576 18.4869 21.664C18.9541 21.4705 19.2588 21.0146 19.2588 20.5088V19.2369C20.6925 18.8994 21.7597 17.6119 21.7597 16.0752V12.0016C21.7597 10.2078 20.3056 8.75363 18.5117 8.75363H18.0079Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsCommunicationMessageChat);
