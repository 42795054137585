import * as React from 'react';

function IconArrowChevronLeftArrowRight(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.114 17.796a.889.889 0 000 1.208l.27.292c.352.38.953.38 1.304 0l6.196-6.692a.89.89 0 000-1.208l-6.196-6.692a.889.889 0 00-1.304 0l-.27.292a.889.889 0 000 1.208L14.48 12l-5.367 5.796z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default React.memo(IconArrowChevronLeftArrowRight);
