import * as React from 'react';

function IconsOtherShapesObjects(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.223 4.07169L8.79673 6.49796C8.36808 6.92736 8.36851 7.62381 8.79636 8.05166L11.223 10.4783C11.6524 10.9067 12.3489 10.9064 12.7767 10.4787L15.2033 8.05203C15.6318 7.62261 15.6315 6.92611 15.2037 6.49832L12.7771 4.07169C12.3479 3.6433 11.6521 3.6433 11.223 4.07169ZM13.837 3.0103C12.8222 1.99689 11.1779 1.99689 10.1631 3.0103L7.7357 5.43766C6.7225 6.45227 6.7216 8.09823 7.7357 9.11232L10.1627 11.5393C11.1773 12.5525 12.8233 12.5534 13.8374 11.5393L16.2644 9.11232C17.2776 8.09772 17.2785 6.45176 16.2644 5.43766L13.837 3.0103ZM15.666 14.549C15.0494 14.549 14.549 15.05 14.549 15.666V19.133C14.549 19.7498 15.0492 20.25 15.666 20.25H19.1329C19.75 20.25 20.25 19.7495 20.25 19.134V15.667C20.25 15.0505 19.7499 14.5498 19.1325 14.549H15.666ZM13.049 15.666C13.049 14.2221 14.2205 13.049 15.666 13.049H19.1329C20.5775 13.0504 21.75 14.2217 21.75 15.667V19.134C21.75 20.5785 20.5779 21.75 19.1329 21.75H15.666C14.2207 21.75 13.049 20.5782 13.049 19.133V15.666ZM3.75 17.4C3.75 15.8262 5.02621 14.55 6.6 14.55C8.17379 14.55 9.45 15.8262 9.45 17.4C9.45 18.9738 8.17379 20.25 6.6 20.25C5.02621 20.25 3.75 18.9738 3.75 17.4ZM2.25 17.4C2.25 19.8022 4.19779 21.75 6.6 21.75C9.00221 21.75 10.95 19.8022 10.95 17.4C10.95 14.9978 9.00221 13.05 6.6 13.05C4.19779 13.05 2.25 14.9978 2.25 17.4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsOtherShapesObjects);
