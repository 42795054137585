import * as React from 'react';

function IconsOtherFlash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8456 2.78326C17.1603 2.88012 17.375 3.17085 17.375 3.50006V9.54566L21.2876 9.55406C21.5651 9.55465 21.8197 9.7085 21.9492 9.95398C22.0788 10.1995 22.0622 10.4964 21.9061 10.7259L14.9801 20.9069C14.7949 21.1791 14.4539 21.2987 14.1393 21.2019C13.8246 21.105 13.61 20.8143 13.61 20.4851V14.4394L9.69834 14.4311C9.42078 14.4305 9.16625 14.2766 9.03668 14.0312C8.90711 13.7857 8.92371 13.4887 9.07981 13.2592L16.0048 3.07824C16.19 2.80603 16.531 2.68639 16.8456 2.78326ZM11.1151 12.9341L14.3616 12.9411C14.7751 12.9419 15.11 13.2775 15.11 13.6911V18.0492L19.8707 11.051L16.6233 11.0441C16.2098 11.0432 15.875 10.7076 15.875 10.2941V5.93622L11.1151 12.9341Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.94995 6.50006C1.94995 6.08584 2.28574 5.75006 2.69995 5.75006H9.69995C10.1142 5.75006 10.45 6.08584 10.45 6.50006C10.45 6.91427 10.1142 7.25006 9.69995 7.25006H2.69995C2.28574 7.25006 1.94995 6.91427 1.94995 6.50006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.94995 18.5001C1.94995 18.0858 2.28574 17.7501 2.69995 17.7501H9.69995C10.1142 17.7501 10.45 18.0858 10.45 18.5001C10.45 18.9143 10.1142 19.2501 9.69995 19.2501H2.69995C2.28574 19.2501 1.94995 18.9143 1.94995 18.5001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.94995 12.5001C1.94995 12.0858 2.28574 11.7501 2.69995 11.7501H5.69995C6.11416 11.7501 6.44995 12.0858 6.44995 12.5001C6.44995 12.9143 6.11416 13.2501 5.69995 13.2501H2.69995C2.28574 13.2501 1.94995 12.9143 1.94995 12.5001Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsOtherFlash);
