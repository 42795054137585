import * as React from 'react';

function IconsCoursePlay(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.054 11.364L8.407 3.728a.788.788 0 00-.797-.038.762.762 0 00-.41.673v15.273c0 .282.157.54.41.674a.788.788 0 00.797-.039l11.647-7.636a.761.761 0 00.345-.635.761.761 0 00-.345-.636z"
      />
    </svg>
  );
}

export default React.memo(IconsCoursePlay);
