import * as React from 'react';

function IconsRefesh(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.5 4.205A9 9 0 005.206 7.5L2.543 6.063a.5.5 0 00-.718.577l1.538 5.38a.5.5 0 00.618.343l5.367-1.533a.5.5 0 00.107-.917L6.938 8.499A6.995 6.995 0 0116.5 5.937a6.995 6.995 0 012.562 9.562 6.995 6.995 0 01-11.732.6c-.284-.396-.782-.605-1.248-.459-.6.188-.893.871-.542 1.393A9 9 0 1017.5 4.205z"
      />
    </svg>
  );
}

export default React.memo(IconsRefesh);
