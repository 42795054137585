import * as React from 'react';

function IconArrowChevronLeftArrowLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.884 6.204a.889.889 0 000-1.208l-.27-.292a.889.889 0 00-1.304 0l-6.196 6.692a.889.889 0 000 1.208l6.196 6.692c.352.38.952.38 1.304 0l.27-.292a.889.889 0 000-1.208L9.518 12l5.366-5.796z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default React.memo(IconArrowChevronLeftArrowLeft);
