import * as React from 'react';

function IconsLearningMonitorGraduateHat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18.25H8.94455L8.80169 20.25H8C7.58579 20.25 7.25 20.5858 7.25 21C7.25 21.4142 7.58579 21.75 8 21.75H16C16.4142 21.75 16.75 21.4142 16.75 21C16.75 20.5858 16.4142 20.25 16 20.25H15.1984L15.0555 18.25H19C20.7949 18.25 22.25 16.7949 22.25 15V5.5C22.25 3.70507 20.7949 2.25 19 2.25H5C3.20507 2.25 1.75 3.70507 1.75 5.5V15C1.75 16.7949 3.20507 18.25 5 18.25ZM5 3.75C4.0335 3.75 3.25 4.5335 3.25 5.5V15C3.25 15.9665 4.0335 16.75 5 16.75H19C19.9665 16.75 20.75 15.9665 20.75 15V5.5C20.75 4.5335 19.9665 3.75 19 3.75H5ZM10.3055 20.25L10.4484 18.25H13.5517L13.6945 20.25H10.3055ZM13.3777 5.83502C12.5136 5.38833 11.4866 5.38833 10.6225 5.83502L7.56127 7.41746C7.06297 7.67501 6.75 8.18907 6.75 8.75C6.75 9.31093 7.06304 9.82503 7.56134 10.0826L8.75 10.697V12.5198C8.75 13.3152 9.19948 14.0424 9.91094 14.3981L10.3231 14.6041C11.3787 15.1319 12.6214 15.1319 13.677 14.6041L14.0891 14.3981C14.8006 14.0423 15.25 13.3152 15.25 12.5198V10.6971L15.75 10.4387V10.4973C15.75 10.9115 16.0858 11.2473 16.5 11.2473C16.9142 11.2473 17.25 10.9115 17.25 10.4973V8.77249L17.2502 8.75C17.2502 8.18904 16.9372 7.67496 16.4388 7.41742L13.3777 5.83502ZM9.83337 9.56846C9.84045 9.57198 9.84747 9.57561 9.85442 9.57934L11.3113 10.3325C11.7433 10.5558 12.2568 10.5558 12.6889 10.3325L14.141 9.58184C14.151 9.57638 14.1611 9.57114 14.1714 9.56613L15.7501 8.75003L12.6889 7.16753C12.2568 6.94419 11.7433 6.94419 11.3113 7.16753L8.25009 8.74997L9.83337 9.56846ZM10.25 12.5198V11.4724L10.6224 11.665C11.4866 12.1117 12.5136 12.1117 13.3777 11.665L13.75 11.4725V12.5198C13.75 12.747 13.6216 12.9548 13.4183 13.0564L13.0062 13.2624C12.3728 13.5791 11.6271 13.5791 10.9937 13.2624L10.5816 13.0564C10.3784 12.9547 10.25 12.747 10.25 12.5198Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsLearningMonitorGraduateHat);
