import * as React from 'react';

function IconArrowArrowRightLine(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.293 6.707a1 1 0 011.414-1.414l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L16.586 13H4a1 1 0 110-2h12.586l-4.293-4.293z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default React.memo(IconArrowArrowRightLine);
