import * as React from 'react';

function IconsVideoMovies(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10.0469V10.0469C17.5 14.1894 14.1425 17.5469 10 17.5469V17.5469C5.8575 17.5469 2.5 14.1894 2.5 10.0469V10.0469C2.5 5.90437 5.8575 2.54688 10 2.54688V2.54688C14.1425 2.54688 17.5 5.90437 17.5 10.0469Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4.04"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33876 7.64244L12.4171 9.46327C12.8604 9.72577 12.8604 10.3674 12.4171 10.6299L9.33793 12.4508C8.88626 12.7183 8.31543 12.3924 8.31543 11.8674V8.22577C8.31543 7.70077 8.8871 7.37494 9.33876 7.64244V7.64244Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconsVideoMovies);
