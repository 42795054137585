import * as React from 'react';

function IconsJuniorStar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1766 5.58135L13.9226 5.90642C14.6037 5.9606 14.8746 6.81196 14.356 7.26087L11.5156 9.7221L12.3669 13.383C12.5217 14.0486 11.8019 14.5749 11.2137 14.2189L8.00174 12.2762L4.78976 14.2111C4.20155 14.5672 3.48175 14.0409 3.63655 13.3752L4.48791 9.7221L1.64744 7.26087C1.12888 6.81196 1.40751 5.9606 2.0886 5.90642L5.82688 5.58909L7.28969 2.13718C7.55284 1.51026 8.45065 1.51026 8.7138 2.13718L10.1766 5.58135ZM5.09161 12.5858L8.00174 10.8289L10.9196 12.5935L10.1456 9.28093L12.7152 7.0519L9.32523 6.75779L8.00174 3.63094L6.68599 6.75005L3.296 7.04416L5.86558 9.27319L5.09161 12.5858Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsJuniorStar);
