import * as React from 'react';

function IconsUserGroup(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2622 10.0538C19.765 9.55671 18.959 9.55671 18.4619 10.0538C17.9647 10.551 17.9647 11.357 18.4619 11.8541C18.959 12.3513 19.765 12.3513 20.2622 11.8541C20.7593 11.357 20.7593 10.551 20.2622 10.0538ZM21.322 8.99233C20.239 7.91027 18.4839 7.91055 17.4012 8.99319C16.3183 10.0761 16.3183 11.8319 17.4012 12.9148C18.4841 13.9977 20.2399 13.9977 21.3228 12.9148C22.4058 11.8319 22.4058 10.0761 21.3228 8.99319C21.3225 8.9929 21.3223 8.99262 21.322 8.99233Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6723 6.44269C12.7487 5.5191 11.2512 5.5191 10.3277 6.44269C9.40406 7.36629 9.40406 8.86372 10.3277 9.78731C11.2512 10.7109 12.7487 10.7109 13.6723 9.78731C14.5959 8.86371 14.5959 7.36628 13.6723 6.44269ZM14.7326 5.38165C13.2231 3.87266 10.7762 3.87278 9.26699 5.38203C7.75762 6.89141 7.75762 9.3386 9.26699 10.848C10.7764 12.3573 13.2236 12.3573 14.7329 10.848C16.2423 9.33859 16.2419 6.89103 14.7326 5.38165C14.7327 5.38178 14.7324 5.38153 14.7326 5.38165Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53815 10.0538C5.04101 9.55671 4.235 9.55671 3.73786 10.0538C3.24072 10.551 3.24072 11.357 3.73786 11.8541C4.235 12.3513 5.04101 12.3513 5.53815 11.8541C6.03529 11.357 6.03529 10.551 5.53815 10.0538ZM6.59795 8.99233C5.51496 7.91027 3.75984 7.91055 2.6772 8.99319C1.59428 10.0761 1.59428 11.8319 2.6772 12.9148C3.76012 13.9977 5.51589 13.9977 6.59881 12.9148C7.68173 11.8319 7.68173 10.0761 6.59881 8.99319C6.59852 8.9929 6.59824 8.99262 6.59795 8.99233Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.949 15.404C18.949 14.9898 19.2848 14.654 19.699 14.654H20.5C22.2952 14.654 23.75 16.1088 23.75 17.904V19C23.75 19.4142 23.4142 19.75 23 19.75C22.5858 19.75 22.25 19.4142 22.25 19V17.904C22.25 16.9372 21.4668 16.154 20.5 16.154H19.699C19.2848 16.154 18.949 15.8182 18.949 15.404Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 16.154C2.53321 16.154 1.75 16.9372 1.75 17.904V19C1.75 19.4142 1.41421 19.75 1 19.75C0.585786 19.75 0.25 19.4142 0.25 19V17.904C0.25 16.1088 1.70479 14.654 3.5 14.654H4.301C4.71521 14.654 5.051 14.9898 5.051 15.404C5.051 15.8182 4.71521 16.154 4.301 16.154H3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91 17.399C5.91 15.0518 7.81279 13.149 10.16 13.149H13.839C16.1862 13.149 18.089 15.0518 18.089 17.399V19C18.089 19.4142 17.7532 19.75 17.339 19.75C16.9248 19.75 16.589 19.4142 16.589 19V17.399C16.589 15.8802 15.3578 14.649 13.839 14.649H10.16C8.64121 14.649 7.41 15.8802 7.41 17.399V19C7.41 19.4142 7.07421 19.75 6.66 19.75C6.24579 19.75 5.91 19.4142 5.91 19V17.399Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsUserGroup);
