import * as React from 'react';

function IconsFeatureBulbV2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.34039 2.27973C4.0475 1.98684 3.57262 1.98684 3.27973 2.27973C2.98684 2.57262 2.98684 3.0475 3.27973 3.34039L4.46973 4.53039C4.76262 4.82328 5.2375 4.82328 5.53039 4.53039C5.82328 4.2375 5.82328 3.76262 5.53039 3.46973L4.34039 2.27973Z"
        fill="currentColor"
      />
      <path
        d="M21.7203 4.34039C22.0132 4.0475 22.0132 3.57262 21.7203 3.27973C21.4274 2.98684 20.9526 2.98684 20.6597 3.27973L19.4697 4.46973C19.1768 4.76262 19.1768 5.2375 19.4697 5.53039C19.7626 5.82328 20.2374 5.82328 20.5303 5.53039L21.7203 4.34039Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25006 18.0097V18.5C8.25006 20.2952 9.70485 21.75 11.5001 21.75H12.5001C14.2953 21.75 15.7501 20.2952 15.7501 18.5V15.733C15.7501 15.675 15.781 15.5874 15.8898 15.5105C17.6163 14.2907 18.7501 12.2799 18.7501 9.99996C18.7501 6.0044 15.2767 2.81823 11.1878 3.29806C8.23432 3.64408 5.79213 5.98077 5.33399 8.92255C4.91297 11.6306 6.11198 14.0978 8.10824 15.5085C8.2174 15.5859 8.25006 15.6752 8.25006 15.737V17.9903C8.25002 17.9935 8.25 17.9968 8.25 18C8.25 18.0032 8.25002 18.0065 8.25006 18.0097ZM17.2501 9.99996C17.2501 6.89159 14.5495 4.41378 11.3625 4.78785C9.07413 5.05589 7.17009 6.88111 6.81616 9.15318L17.2501 9.99996ZM17.2501 9.99996L6.81616 9.15318C6.48727 11.269 7.41819 13.1841 8.97388 14.2835C9.04106 14.331 9.10603 14.3834 9.16728 14.4399H14.8329C14.8937 14.3841 14.9577 14.3325 15.0243 14.2854C16.3719 13.3333 17.2501 11.77 17.2501 9.99996ZM9.75006 15.9399H14.2501V17.25H9.75006V15.9399ZM9.76777 18.75H14.2323C14.1111 19.5983 13.382 20.25 12.5001 20.25H11.5001C10.6182 20.25 9.88903 19.5983 9.76777 18.75Z"
        fill="currentColor"
      />
      <path
        d="M4.53039 14.4697C4.82328 14.7626 4.82328 15.2374 4.53039 15.5303L3.34039 16.7203C3.0475 17.0132 2.57262 17.0132 2.27973 16.7203C1.98684 16.4274 1.98684 15.9526 2.27973 15.6597L3.46973 14.4697C3.76262 14.1768 4.2375 14.1768 4.53039 14.4697Z"
        fill="currentColor"
      />
      <path
        d="M19.4697 14.4697C19.7626 14.1768 20.2374 14.1768 20.5303 14.4697L21.7203 15.6597C22.0132 15.9526 22.0132 16.4274 21.7203 16.7203C21.4274 17.0132 20.9526 17.0132 20.6597 16.7203L19.4697 15.5303C19.1768 15.2374 19.1768 14.7626 19.4697 14.4697Z"
        fill="currentColor"
      />
      <path
        d="M21 9.25C20.5858 9.25 20.25 9.58579 20.25 10C20.25 10.4142 20.5858 10.75 21 10.75H22.69C23.1042 10.75 23.44 10.4142 23.44 10C23.44 9.58579 23.1042 9.25 22.69 9.25H21Z"
        fill="currentColor"
      />
      <path
        d="M0.560059 10C0.560059 9.58579 0.895845 9.25 1.31006 9.25H3.00006C3.41427 9.25 3.75006 9.58579 3.75006 10C3.75006 10.4142 3.41427 10.75 3.00006 10.75H1.31006C0.895845 10.75 0.560059 10.4142 0.560059 10Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsFeatureBulbV2);
