import * as React from 'react';

function IconsEssentialSearchStar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.261 1.77667C10.4068 0.115142 5.91899 1.17956 3.22163 4.39501C0.524261 7.61047 0.256749 12.2149 2.56361 15.721C4.87046 19.2272 9.20485 20.8041 13.2255 19.6C14.4365 19.2374 15.5326 18.6484 16.4731 17.8876L21.2964 22.7109C21.687 23.1014 22.3201 23.1014 22.7107 22.7109C23.1012 22.3204 23.1012 21.6872 22.7107 21.2967L17.887 16.4731C19.2212 14.8229 20.0001 12.7314 20 10.4993C20 9.94705 19.5522 9.49936 18.9999 9.4994C18.4477 9.49944 18 9.94718 18 10.4995C18.0002 13.8127 15.826 16.7335 12.6518 17.6841C9.47749 18.6347 6.05557 17.3897 4.23441 14.6218C2.41325 11.8538 2.62443 8.21886 4.75389 5.68038C6.88337 3.14188 10.4264 2.30152 13.4693 3.61327C13.9764 3.83191 14.5648 3.59801 14.7835 3.09085C15.0021 2.58368 14.7682 1.9953 14.261 1.77667ZM20 1.00006C20.5523 1.00006 21 1.44778 21 2.00006V3.00027H22C22.5523 3.00027 23 3.44799 23 4.00027C23 4.55256 22.5523 5.00027 22 5.00027H21V6.00006C21 6.55235 20.5523 7.00006 20 7.00006C19.4477 7.00006 19 6.55235 19 6.00006V5.00027H18C17.4477 5.00027 17 4.55256 17 4.00027C17 3.44799 17.4477 3.00027 18 3.00027H19V2.00006C19 1.44778 19.4477 1.00006 20 1.00006ZM13.5 6.00006C14.0523 6.00006 14.5 6.44778 14.5 7.00006V8.00027H15.5C16.0523 8.00027 16.5 8.44799 16.5 9.00027C16.5 9.55256 16.0523 10.0003 15.5 10.0003H14.5V11.0001C14.5 11.5523 14.0523 12.0001 13.5 12.0001C12.9477 12.0001 12.5 11.5523 12.5 11.0001V10.0003H11.5C10.9477 10.0003 10.5 9.55256 10.5 9.00027C10.5 8.44799 10.9477 8.00027 11.5 8.00027H12.5V7.00006C12.5 6.44778 12.9477 6.00006 13.5 6.00006Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsEssentialSearchStar);
