import * as React from 'react';

function IconsSchoolLearningGraduateHat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08613 3.45949L2.94113 6.31783C1.85947 6.91866 1.85947 8.47366 2.94113 9.07449L8.08613 11.9328C9.27613 12.5937 10.7236 12.5937 11.9145 11.9328L17.0595 9.07449C18.1411 8.47366 18.1411 6.91866 17.0595 6.31783L11.9145 3.45949C10.7236 2.79866 9.27697 2.79866 8.08613 3.45949Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99316 10.2139V13.4839C4.99316 14.2747 5.389 15.0122 6.0465 15.4497L7.38566 16.3405C8.96983 17.3939 11.0315 17.3939 12.6148 16.3405L13.954 15.4497C14.6123 15.0122 15.0073 14.2739 15.0073 13.4839V10.2139"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconsSchoolLearningGraduateHat);
