import * as React from 'react';

function IconsTimeCalendarScheduleTime1(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.49826 2.99628C8.49826 2.44399 8.05054 1.99628 7.49826 1.99628C6.94597 1.99628 6.49826 2.44399 6.49826 2.99628V3.49689H5.99734C3.78751 3.49689 1.99609 5.28831 1.99609 7.49814V18.0025C1.99609 20.2123 3.78751 22.0038 5.99734 22.0038H7.49951C8.0518 22.0038 8.49951 21.556 8.49951 21.0038C8.49951 20.4515 8.0518 20.0038 7.49951 20.0038H5.99734C4.89208 20.0038 3.99609 19.1078 3.99609 18.0025V7.49814C3.99609 6.39288 4.89208 5.49689 5.99734 5.49689H6.49826V5.99753C6.49826 6.54981 6.94597 6.99753 7.49826 6.99753C8.05054 6.99753 8.49826 6.54981 8.49826 5.99753V5.49689H15.5017V5.99753C15.5017 6.54981 15.9494 6.99753 16.5017 6.99753C17.054 6.99753 17.5017 6.54981 17.5017 5.99753V5.49689H18.0023C19.1076 5.49689 20.0036 6.39288 20.0036 7.49814V8C20.0036 8.55228 20.4513 9 21.0036 9C21.5559 9 22.0036 8.55228 22.0036 8V7.49814C22.0036 5.28831 20.2122 3.49689 18.0023 3.49689H17.5017V2.99628C17.5017 2.44399 17.054 1.99628 16.5017 1.99628C15.9494 1.99628 15.5017 2.44399 15.5017 2.99628V3.49689H8.49826V2.99628Z"
        fill="currentColor"
      />
      <path
        d="M17.0507 14.519C17.0507 14.0496 16.6701 13.669 16.2007 13.669C15.7312 13.669 15.3507 14.0496 15.3507 14.519V16.803C15.3507 17.2724 15.7312 17.653 16.2007 17.653H18.0965C18.5659 17.653 18.9465 17.2724 18.9465 16.803C18.9465 16.3336 18.5659 15.953 18.0965 15.953H17.0507V14.519Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16.5C10 12.9101 12.9101 10 16.5 10C20.0899 10 23 12.9101 23 16.5C23 20.0899 20.0899 23 16.5 23C12.9101 23 10 20.0899 10 16.5ZM16.5 12C14.0147 12 12 14.0147 12 16.5C12 18.9853 14.0147 21 16.5 21C18.9853 21 21 18.9853 21 16.5C21 14.0147 18.9853 12 16.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsTimeCalendarScheduleTime1);
